<template>
  <div>
    <div class="page-section-alt">
      <banner :title="meta.title" />

      <v-container>
        <v-row>
          <v-col>
            <h1><strong>BÖLÜM I – KURULUŞ HÜKÜMLERİ </strong></h1>
            <h2><strong>Madde 1 – VAKFIN ADI</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfın adı &ldquo;Kadın Hekimler Eğitime Destek
                  Vakfı&rdquo;dır. Bu vakıf senedinde kısaca &ldquo;Vakıf&rdquo;
                  diye anılacaktır.
                </li>
                <li>
                  Vakıf; çalışmalarında kısaltma olarak, amblem veya logosu ile
                  birlikte veya müstakil olarak &ldquo;KAHEV&rdquo; ibaresini
                  kullanacaktır.
                </li>
                <li>
                  Kadın Hekimler Eğitime Destek Vakfı&rsquo;nın amblemi; kırmızı
                  kalp şeklinde stetoskobun üzerine yazılı KAHEV ve onun altında
                  KADIN HEKİMLER EĞİTİME DESTEK VAKFI yazılarından oluşmaktadır.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 2 – VAKFIN MERKEZİ</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfın merkezi; Eskişehir İli, Tepebaşı İlçesindedir. Adresi:
                  &ldquo;Hoşnudiye Mah. Kadife
                </li>
              </ol>
            </ol>
            <p>Çiçeği Sok. No:1 D:12 Kat:6 Tepebaşı/Eskişehir&rdquo;dir.</p>
            <ol>
              <ol>
                <li>
                  İlgili mevzuatlar çerçevesinde; vakıf yönetim kurulu kararı
                  ile yurtiçinde veya dışında şube, temsilcilik veya benzeri
                  teşkilât kurabilir; Eskişehir sınırları içerisinde adres
                  değiştirilebilir.
                </li>
                <li>
                  Şubelerin, temsilcilerin veya kurulacak diğer teşkilâtların
                  görev ve yetkileri vakıf yönetim kurulunca belirlenir.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 3 – VAKFIN AMACI</strong></h2>
            <p>
              <strong>3.1.</strong> Vakfın amacı; okul öncesi, ilk, orta ve
              yükseköğretim düzeyindeki öğrencilerin eğitimine yönelik burs
              sağlamak, ülkemizin kültürel, bilimsel ve sosyal gelişmesine
              hizmet etmek, Atatürk ilke ve inkılaplarına bağlı, verimli, etkin
              ve yüksek düzeyde eğitim hizmetlerinin yerine getirilmesine
              katkıda bulunmak, eğitim çerçevesi içinde katılımcı bir sivil
              toplum hareketi yaratmak, Türkiye&rsquo;de çağdaş ve en ileri
              eğitim sistemlerinin uygulanmasına ve Türk toplumuna yararlı
              vatandaşlar yetiştirilmesine katkıda bulunmak, sürdürülebilir bir
              yaşam için doğa sevgisini arttırmaya ve çevreyi korumaya yönelik
              eğitim ve faaliyetlerde bulunmaktır.
            </p>
            <h2><strong>Madde 4 – VAKFIN FAALİYETLERİ</strong></h2>
            <p>
              Vakıf, amaçlarını gerçekleştirmek için aşağıda belirtilen
              faaliyetleri yürütmeye yetkilidir. Bu doğrultuda;
            </p>
            <ol>
              <ol>
                <li>
                  Amaç konusu ile ilgili derleme ve araştırmalarda bulunmak,
                  bunları yayınlamak, eğitimin etkinliğini arttırıcı
                  faaliyetlerde bulunmak, müessese ve eğitim ile ilgili
                  yarışmalar açmak, memlekete yararlı hizmetlerde bulunanlara
                  ödüller vermek ve çalışmalarında yardımcı olmak, aynı amaç
                  doğrultusunda çalışan kurum ve kuruluşlar arasında ortaklaşa
                  gayret, ahenkli ilişki ve bütünleşme sağlamak.
                </li>
                <li>
                  Maddi olanaklardan yoksun tüm Türkiye Cumhuriyeti vatandaşı,
                  okul öncesi, ilk-orta öğretim, yükseköğrenim gören veya
                  üniversite mezunu olan başarılı öğrencilere, yurt içinde
                  ve/veya yurtdışında karşılıklı ya da karşılıksız burs vermek,
                  nakdi ve ayni yardımlar yapmak, diğer öğrenim ihtiyaçlarını
                  sağlamak (Burs verilecek ve/veya her türlü nakdi ve ayni
                  yardım yapılacak öğrenciler için gerekli şartlar, belgeler ve
                  başvuru yöntemleri Yönetim Kurulu kararıyla belirlenir).
                </li>
                <li>
                  Okul öncesi, ilk ve orta öğretim, yükseköğrenim eğitim
                  tesisleri, kuruluşları ve kurumları, konferans salonları,
                  kütüphaneler kurmak eğitimi ve sporu destekleyici faaliyetler
                  yaparak bunları destekleyecek tesisler kurmak, yurt açmak,
                  kurulmuş olan tesislerde tadil, ilâve ve tamirler yapmak,
                  kitap, ders malzemesi gibi araç ve gereçlerin temininde
                  yardımcı olmak.
                </li>
                <li>
                  Vakfın amacına yönelik seminerler, kurslar, interaktif
                  eğitimler düzenlemek, bu konularla uğraşan okul, kulüp, dernek
                  ve vakıf gibi kuruluşlarla iş birliği yapmak, bunları bir
                  araya toplamak, faaliyetlerine etkinlik kazandırmak ve
                  koordinasyon ile bütünleşme sağlamak.
                </li>
                <li>
                  Öğretimin yanı sıra kültürel etkinlikleri desteklemek ve
                  öncülüğünü yapmak ve bu bağlamda müzik, resim, tiyatro,
                  edebiyat, folklor, spor ve benzeri bütün dallarda, yurt
                  çapında ve uluslararası etkinliklere katılmak ve bunları
                  teşvik etmek, kültürel etkinlikleri ödüllendirmek, destek
                  amacıyla sponsorluk yapmak.
                </li>
                <li>
                  Vakfın amacının gerçekleştirilmesi için eğitim, konferans,
                  sergiler, konserler ve benzeri etkinlikler düzenlemek kitap,
                  dergi, broşür gibi yayımlar yapmak.
                </li>
                <li>
                  Dil, din, ırk, cinsiyet ve renk ayırt etmeksizin hekimlerin
                  vefatı veya maluliyeti durumunda, vakfa başvurulması halinde
                  çocuklarına eğitim öğretim hayatı boyunca burs vermek ve
                  gerektiğinde öncelik sağlamak.
                </li>
                <li>
                  Yönetim kurulu kararı ile özel durumdaki/engelli öğrencilere,
                  eğitimleri için gerekli araç ve gereç yardımında bulunmak
                  (görmeyi sağlayacak özel lens, tekerlekli sandalye, işitme
                  cihazı, vb).
                </li>
                <li>
                  Çevre koruma ve ağaçlandırma konularında eğitim ve
                  faaliyetlerde bulunmak.
                </li>
                <li>
                  <a name="_Hlk73542140"
                    >Mevcut ya da kendi kuracağı orman alanlarına veya tahrip
                    olmuş ve yanmış alanlarla, rekreasyon alanlarına ağaç dikimi
                    yapmak, rekreasyon alanları, fidanlıklar benzerleri
                    kurmak.</a
                  >
                </li>
                <li>
                  Ülkemiz insanlarını çevre, orman ve ağaç sevgisi konularında
                  eğitmek, bilgi, görgü ve gelişimini arttırmak amacıyla
                  kültürel ve sanatsal etkinlikler düzenlemek, yayınlar yapmak
                  ve ayrıca basın ve yayın kuruluşları ile iş birliği yapmak.
                </li>
                <li>
                  Ülkemizde yaşanan deprem, yangın vb. doğal afetler nedeniyle
                  afetzedelere yardımda bulunmak, afetten etkilenen bölgedeki
                  okullara eğitim faaliyetlerini destekleyecek tesisler kurmak
                  veya mevcut tesislerde tadil, ilâve ve tamirler yapmak ve
                  afetten etkilenen bölgedeki öğrencilere burs vermek üzere afet
                  fonu oluşturmak.
                </li>
              </ol>
            </ol>
            <h2>
              <strong
                >Madde 5 – VAKFIN AMACINA ULAŞABİLMESİ İÇİN YAPABİLECEĞİ
                TASARRUFLAR</strong
              >
            </h2>
            <p>
              Vakıf, amacını gerçekleştirmek üzere her türlü hukukî muamele ve
              tasarruflarda bulunabilir. Bu amaçla ilgili mevzuat esasları
              dâhilinde olmak üzere:
            </p>
            <ol>
              <ol>
                <li>
                  Taşınır ve taşınmaz mallara bağış, vasiyet, satın alma ve
                  kiralama suretiyle sahip olmaya ve kullanmaya,
                </li>
                <li>
                  Vakıflara ilişkin yasa hükümleri uyarınca sahip oldukları
                  taşınır ve taşınmaz malları satmaya, devir ve ferağ etmeye,
                  gelirlerini almaya ve harcamaya,
                </li>
                <li>
                  Vakıf malvarlığına giren bir ya da birden çok taşınır ve/veya
                  taşınmaz mal veya gelirlerini bir ya da birçok kez yatırımda
                  kullanmaya,
                </li>
                <li>
                  Vakıf amaç ve hizmet konularına aykırı olmamak koşulu ile
                  yapılacak bağış ve vasiyet, satın alma ve diğer yollarla mal
                  ettiği taşınır ve taşınmaz malları vakfın amacı doğrultusunda
                  yönetim ve tasarrufa, menkul değerleri almaya ve değerlendirip
                  satmaya,
                </li>
                <li>
                  Vakfın amaçlarına benzer çalışmalarda bulunan yurtiçi ve yurt
                  dışındaki vakıflar, dernekler ile gerçek ve tüzel kişiler ile
                  iş birliği yapmaya, kamu kurum ve kuruluşları dışındakilerden
                  yardım almaya, bu yardımı sağlamak için anlaşmalar yapmaya,
                </li>
                <li>
                  Taşınmaz malların irtifak, intifa, sükna, üst, rehin, ipotek
                  gibi mülkiyetten gayri ayni haklarını kabule, bu hakları
                  kullanmaya, olan ya da olacak gelirleri ile kuracağı
                  sözleşmeler için taşınır ve taşınmaz malların rehin ve ipoteği
                  dahil her türlü güvenceleri almaya, geçerli banka
                  kefaletlerini kabule,
                </li>
                <li>
                  Bankalarda vakıf adına vadeli döviz ve/veya Türk lirası hesabı
                  açmaya kapamaya,
                </li>
                <li>
                  Gerektiğinde ödünç almaya, kefalet, rehin, ipotek ve diğer
                  güvenceleri vermeye,
                </li>
                <li>
                  Yürütülen ve yürütülecek projelerden ve her türlü
                  çalışmalardan gelir elde etmeye ve vakfa gelir sağlamak amacı
                  ile olağan işletme ilkelerine göre çalışacak iktisadi
                  işletmeler, şirketler kurmaya, kurulu olanlara iştirake,
                  bunları doğrudan işletmeye ya da denetimi altında bir
                  işletmeciye işlettirmeye,
                </li>
                <li>
                  Girişim, tasarruf, mal edinme, inşaat ve benzeri sözleşmeleri
                  yapmaya,
                </li>
                <li>
                  Şirketlerin pay senetlerini, intifa senetlerini, tahvil,
                  finansman bonosu, mevduat sertifikası, hazine bonosu, yatırım
                  fonlarını, obligasyonlarını veya diğer hisse temsil eden veya
                  mevcut veya vücut bulacak bir hak veya alacak ifade eden
                  evrakını, hisse senetlerini, bütün bunlara ait kuponları ve
                  diğer menkul değerleri almaya, değerlendirmeye, satmaya,
                  gelirlerini tahsil ve sarfa,
                </li>
                <li>
                  Yaptığı ticari ortaklıklar çerçevesinde bazı
                  ürünleri/hizmetleri imal, ihraç ve ithal etmeye, hissesine
                  isabet eden temettü ya da kar paylarını benzer biçimde
                  değerlendirmeye, borç alıp- vermeye, amacına uygun her türlü
                  bağışı kabul etmeye, vakıf borçları ve alacaklarına ilişkin
                  her türlü yetkiyi kullanmakta,
                </li>
              </ol>
            </ol>
            <p>
              <strong>5.13</strong> Vakıf üyeliğine giriş aidatı ile vakıf
              üyelerinden aidatlar alarak vakfın amacının gerçekleşmesine katkı
              sağlamaya, <br />
              Türk Medeni Kanunu&rsquo;nun 48. Maddesinde belirtildiği üzere
              izinli ve yetkilidir. Vakıf bu yetki ve gelirlerini Türk Medeni
              Kanunu ile yasaklanan maksatlarla kullanamaz.
            </p>
            <h2><strong>Madde 6 – VAKFIN KURULUŞ MALVARLIĞI</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfın kuruluş malvarlığı kurucular tarafından vakfa tahsis
                  edilmiş olan 80.000,00 (Seksen Bin) TL&rsquo;dır.
                </li>
                <li>
                  Vakıf, işbu kuruluş malvarlığını mevzuata uygun olarak
                  bağışlarla, üyelik aidatları ile bunun dışında her türlü
                  sınai, ticari ve hukuki olanaklar ve vakfın amacını
                  gerçekleştirmek için yapılabilecek tasarruflar ile artırır ve
                  malvarlığının değerini koruyucu tedbirler alır.
                </li>
              </ol>
            </ol>
            <h1><strong>BÖLÜM II – YÖNETİM HÜKÜMLERİ</strong></h1>
            <h2><strong>Madde 7 – VAKFIN ORGANLARI</strong></h2>
            <ol>
              <ol>
                <li>Vakfın organları şunlardır:</li>
              </ol>
              <li>Mütevelli Heyeti</li>
              <li>Yönetim Kurulu</li>
              <li>Denetim Kurulu</li>
            </ol>
            <h2><strong>Madde 8 – MÜTEVELLİ HEYETİ</strong></h2>
            <p>
              Vakfın yönetimi ile yükümlü ve sorumlu &ldquo;Yönetim
              Kurulu&rdquo; ile &ldquo;Denetim Kurulu&rdquo;nu seçen, denetleyen
              ve kendisine tevdi edilen diğer görevleri yerine getiren, seçim,
              denetleme, tavsiye, yetkilendirme ve onay ile yetkili vakfın en
              üst organıdır.
            </p>
            <ol>
              <ol>
                <li><strong> Mütevelli Heyetinin Oluşumu</strong></li>
                <ol>
                  <li>
                    Mütevelli Heyeti, vakfın kurucuları olan ve başlangıç
                    hükümlerinde isimleri yazılı kişilerden oluşmaktadır.
                  </li>
                  <li>
                    Herhangi bir nedenle son bulmadıkça Mütevelli Heyeti
                    üyelerinin üyelikleri hayat boyu devam eder. Mütevelli heyet
                    üyesinin; Mütevelli heyet toplantılarına üç kez arka arkaya
                    katılamaması halinde mütevelli heyet üyeliği düşer.
                    Mütevelli heyet üyesinin mütevelli heyet toplantılarına
                    katılabilmesini engelleyecek geçerli bir mazereti bulunması
                    ve bu mazereti gerekçesiyle birlikte yazılı olarak
                    bildirmesi ve mazeretin mütevelli heyetince kabul edilmesi
                    halinde üyelik sıfatı devam eder. Mazeretin kabulü,
                    toplantıda hazır bulunan üye sayısının 2/3&rsquo;ünün
                    kabulüne bağlıdır.
                  </li>
                </ol>
              </ol>
            </ol>
            <p>
              <strong>8.1.3 </strong>Mütevelli Heyet üyeliğinden herhangi bir
              sebeple ayrılan üyelerin vakfa intikal ettirdikleri hak ve
              menfaatler kendisine veya varislerine geri verilmez.<br />
              <strong>8.1.4</strong> Mütevelli Heyet üyelerinin herhangi bir
              nedenle üyeliğinin sona ermesi durumunda boş üyeliğe seçim,
              Mütevelli Heyeti tarafından oyçokluğu ile seçilir. <br />
              <strong>8.1.5</strong> Mütevelli Heyeti Üyelerinin hizmetleri
              fahrîdir, ücret talep edilemez.
            </p>
            <ol>
              <ol>
                <li><strong>Mütevelli Heyeti Görev ve Yetkileri</strong></li>
                <ol>
                  <li>
                    <a name="_Hlk73545464"
                      >Vakıf Mütevelli Heyeti üyelerinin herhangi bir nedenle
                      üyeliğinin sona ermesi durumunda boş üyelik için vakıf
                      üyeleri arasından seçim yapmak</a
                    >, Mütevelli Heyeti Başkanı, Başkan Yardımcısı ve
                    Sekreterini 4 yıllık süre için seçmek,
                  </li>
                  <li>Vakıf Yönetim ve Denetim Kurulu üyelerini seçmek,</li>
                  <li>
                    Vakfın malvarlığı hakkında tasarrufi kararlar almak ve
                    Yönetim Kurulunun bu konu başta olmak üzere mali ve idari
                    yetkilerini belirlemek ve görevlendirmek,
                  </li>
                  <li>
                    Vakıf resmi senedi değişikliğini karara bağlamak ve Vakıf iç
                    mevzuat tasarılarını aynen veya değiştirerek kabul etmek,
                  </li>
                  <li>
                    Ticari ortaklıklar kurması ve kurulu olanlara katılması,
                    iktisadi işletmeler kurması ve bunların amaç ve çalışma
                    konuları ile görev ve yetkilerini belirlemesi ve
                    gerektiğinde bunların feshi veya ortaklık ve
                    işbirliklerinden ayrılması konularında karar vermek,
                    bunların yerine getirilmesi konularında Yönetim Kurulunu
                    görevlendirmek,
                  </li>
                  <li>
                    Gerekli görülen vakıf amacını teşkil eden işlemlerin hepsi
                    için Yönetim Kurulunu görevlendirmek,
                  </li>
                  <li>
                    Vakıf faaliyetleri konusunda genel politikaları belirlemek,
                    yasaların ve buna dayalı diğer hukuki mevzuatların
                    gerektirdiği diğer görevleri yerine getirmektir.
                  </li>
                </ol>
                <li><strong>Mütevelli Heyetinin Toplantıları</strong></li>
                <ol>
                  <li>
                    Mütevelli heyetinin ilk toplantısı, vakfın tescilini
                    müteakip bir ay içinde yapılır.
                  </li>
                  <li>
                    Mütevelli Heyeti &ldquo;Olağan&rdquo; veya
                    &ldquo;Olağanüstü&rdquo; olarak toplanır. Bu toplantılar,
                    Vakıflar Genel Müdürlüğü&rsquo;nün düzenlemelerine uygun bir
                    şekilde yapılır.
                  </li>
                  <li>
                    Olağan toplantı, Yönetim Kurulu&rsquo;nun çağrısı üzerine,
                    her yıl bir defa Ocak-Şubat döneminde yapılır. Toplantının
                    geçerliliği Mütevelli Heyeti üye tam sayısının, yarısından
                    bir fazlasının hazır bulunması koşuluna bağlıdır.
                  </li>
                  <li>
                    Olağan genel kurul çağrısı ardından gerçekleştirilen ilk
                    toplantıda, toplantı yeter sayısı elde edilemezse, toplantı
                    15 gün sonra aynı gün ve saatte, aynı yerde yapılmak üzere
                    ertelenir. Bu ikinci toplantıda toplantı nisabı, üye tam
                    sayısının dörtte birinden (1/4) az olamaz.
                  </li>
                  <li>
                    Her üyenin tek oy hakkı vardır. Senette öngörülen özel
                    hükümler dışında Kararlar katılanların yarısından bir fazla
                    üyenin kabulü ile alınır. Oyların eşit olması halinde
                    Mütevelli Heyeti Başkanının oyu iki oy sayılır. Toplantıya
                    gelemeyecek üye, Mütevelli Heyetinden bir başka üyeyi vekil
                    tayin edebilir. Bir şahısta birden fazla vekâlet toplanamaz.
                  </li>
                  <li>
                    Toplantı hazırlıkları Yönetim Kurulunca, toplantıların
                    yürütülmesi ise toplantıda belirlenecek bir divan başkanınca
                    yürütülür. Divan başkanına bu işin yürütülmesi için yeteri
                    kadar yardımcı seçilebilir. Ancak Mütevelli Heyeti Başkanı
                    dilediği takdirde toplantılarda divan başkanlığını
                    üstlenebilir. Yönetim Kurulu tarafından bir gündem tespit
                    edilir ve toplantı gün yer ve saati, toplantının gündemine
                    göre çalışma raporu, bilanço ve denetçi raporları veya
                    çalışma programı ve bütçe, gündemle birlikte üyelere
                    toplantı tarihinden en az 30 gün önce ellerinde olacak
                    şekilde bizzat tebliğ, iadeli taahhütlü gönderim ya da
                    e-posta yoluyla olacak şekilde gönderilir. Gönderimlerde
                    üyelerin vakfa bildirdikleri adresler ile e-posta adresleri
                    geçerli kabul edilir. Toplantıda gündem okunur ve toplantıya
                    katılanların en az beşte birinin istemi ile toplantıya
                    sadece gündem eklemesi yapılabilir. Bu gündem eklenmesi
                    senet değişikliği, ödenek artırımı, seçim, vakfın feshi ve
                    tasfiyesi ile mütevelliğine son verme istemlerini
                    kapsayamaz.
                  </li>
                  <li>
                    Mütevelli heyeti ayrıca yönetim kurulunun gördüğü lüzum
                    üzerine veya mütevelli heyet üyelerinin en az üçte birinin
                    yazılı olarak yönetim kurulundan talepte bulunmaları halinde
                    olağanüstü olarak toplanabilir.
                  </li>
                  <li>
                    Vakıf üyelerinin en az beşte birinin Vakıf Yönetim Kuruluna
                    yazılı istemi ile toplanılmasını istemesi halinde de Vakıf
                    Yönetim Kurulu Mütevelli Heyetini olağanüstü toplantıya
                    çağırmak zorundadır.
                  </li>
                  <li>
                    Olağanüstü Mütevelli Heyeti toplantılarında, gündemde
                    öngörülmeyen hususlar müzakere edilmez ve karara bağlanmaz.
                  </li>
                </ol>
              </ol>
            </ol>
            <p>
              <strong>8.3.10</strong> Mütevelli Heyetini idare etmek üzere bir
              Başkan, bir Başkan Yardımcısı, bir Oy Toplama Memuru ve bir Kâtip
              seçilir. İbranın ve/veya seçimin söz konusu olduğu toplantılarda,
              Vakıf Yönetim Kurulu Başkanı Divan Başkanı olamaz. Alınan tüm
              kararlar noter onaylı karar defterine yazılarak imzalanır.
              Mütevelli heyeti toplantıları teknik ve hukuki alt yapının olması
              halinde çevrimiçi olarak yapılabilir.
            </p>
            <h2><strong>Madde 9 – YÖNETİM KURULU</strong></h2>
            <p>Vakfın idare ve icra organıdır.</p>
            <ol>
              <ol>
                <li>
                  <strong>Yönetim Kurulu Üyelerinin Seçilme Ehliyeti</strong>
                </li>
                <ol>
                  <li>
                    Yönetim Kurulu&rsquo;nda en az 2 Mütevelli Heyet üyesi
                    bulunur. Yönetim Kurulu Üyeleri, Mütevelli Heyeti tarafından
                    seçilir.
                  </li>
                  <li>
                    Mütevelli Heyeti Üyesi olmayan Yönetim Kurulu üyeleri,
                    Mütevelli Heyeti tarafından vakıf üyeleri arasından
                    seçilirler.
                  </li>
                  <li>
                    Yönetim Kurulu Üyelerinin hizmetleri fahrîdir, ücret talep
                    edilemez.
                  </li>
                </ol>
                <li>
                  <strong>Yönetim Kurulu Üye Sayısı ve Üyelik Süresi</strong>
                </li>
                <ol>
                  <li>Vakıf Yönetim Kurulu 9 Asıl ve 9 Yedek Üyeden oluşur.</li>
                  <li>
                    Yönetim Kurulu Asıl ve Yedek Üyelik süresi 4 yıldır. Tekrar
                    seçilmeleri mümkündür.
                  </li>
                  <li>
                    Vefat, istifa ve yönetim kurulu toplantılarına üç kez arka
                    arkaya gelmemek hallerinde yönetim kurulu üyeliği sıfatı
                    düşer.
                  </li>
                  <li>
                    Üyenin toplantılara fiilen katılabilmesini engelleyecek
                    geçerli bir mazereti bulunması ve bu mazereti gerekçesiyle
                    birlikte yazılı olarak bildirmesi ve mazeretin yönetim
                    kurulunca toplantıda hazır bulunan üye sayısının
                    2/3&rsquo;ünün kabul etmesi halinde üyelik devam eder.
                  </li>
                  <li>
                    Asıl üyelerden birinin üyeliğinin düşmesi halinde, yedek
                    yönetim kurulu üyesinin seçimi sırasında aldıkları oy
                    sırasına göre, şayet oylar eşit çıkması halinde mütevelli
                    heyetinin yarısından bir fazlasının kararı ile belirlenen
                    yedek üye, asıl üye sıfatını kazanır ve yerine seçildiği
                    üyenin kalan süresi kadar görev yapar.
                  </li>
                </ol>
                <li><strong>Yönetim Kurulu Görev Bölümü </strong></li>
                <ol>
                  <li>
                    Yönetim Kurulu, Mütevelli Heyeti tarafından seçiminden sonra
                    ilk toplantısını yapar ve arasından 4 yıl süre ile görev
                    yapma üzere bir başkan, bir başkan yardımcısı, bir sekreter
                    ve bir sayman üye seçer.
                  </li>
                </ol>
                <li><strong>Yönetim Kurulunun Görevleri</strong></li>
                <ol>
                  <li>
                    Yönetim kurulu, vakfın idare ve icra organıdır. Vakfın amacı
                    doğrultusunda her türlü kararı alır, uygular ve genel amacı
                    doğrultusunda her türlü tasarrufta bulunur. Vakıf
                    faaliyetlerinin düzenli ve verimli olarak yürütülmesini
                    sağlar. Alınan kararlar noter tasdikli karar defterine
                    yazılarak imzalanır. Yönetim Kurulu özellikle aşağıdaki
                    hususları ifa eder:
                  </li>
                  <li><strong>Özel Olarak:</strong></li>
                </ol>
              </ol>
              <li>
                Vakıf tüzel kişiliği adına tüm gerçek ve tüzel kişilerle hukuki,
                mali ve sair konularda gerekli girişimlerde bulunmak ve işlemler
                yapmak,
              </li>
              <li>
                Vakıf Mütevelli Heyetini Olağan ve Olağanüstü toplantılara
                çağırmak, bu toplantıların gündemini hazırlamak, Mütevelli
                Heyeti toplantılarıyla ilgili hazırlık işlemlerini yerine
                getirmek,
              </li>
              <li>
                Mütevelli Heyetince belirlenen genel politikalar ışığında, vakıf
                faaliyetlerinin düzenli ve verimli olarak yürütülmesini
                sağlamak. Bu bağlamda gerekli iç mevzuat tasarılarını hazırlamak
                ve Mütevelli Heyetinin kararına sunmak,
              </li>
              <li>
                Vakfın ve iktisadî işletme/işletmelerinin yıllık Faaliyet
                Raporunu, Bilançosunu, Gelir-Gider Hesabı ile Bütçesi&rsquo;ni
                ve çalışma programını hazırlamak ve bunlarla ilgili kararları
                almak ve uygulamak,
              </li>
              <li>
                Mütevelli Heyeti toplantılarında, döneme ait Vakıf Faaliyet
                Raporu&rsquo;nu ibraya sunmak,
              </li>
              <li>
                Her takvim yılının ilk üç ayı içinde vakfın bir önceki yıla ait
                malvarlığı durumunu ve çalışmalarını bir rapor halinde denetim
                makamına bildirmek ve durumun uygun araçlarla yayınlanmasını
                sağlamak,
              </li>
              <li>
                Vakfın malvarlığını amacına uygun şekilde kullanmak, arttırmak
                ve bu maksatla yatırımlar yapmak, sınai ve ticari şirketler
                kurmak, bunları işletmek, kurulmuş olanlara iştirak etmek, vakıf
                malvarlığının değerlendirilmesi ve yeni mali kaynaklara
                kavuşturulması amacıyla çalışmalar yapmak,
              </li>
              <li>
                Vakfın amacına uygun her türlü tasarrufu yapmak, bağışı kabul
                etmek, ölüme bağlı tasarruflarda vakfın amacına ters düşmeyen
                koşul ve yükümlülükleri yerine getirmek,
              </li>
              <li>
                Vakfın idari kadrosunu saptamak, ek hizmet satın alınacak
                personeli (kadın/hekim harici olabilir) atamak, ücretlerini
                tayin etmek, yetki ve sorumluluklarını belirlemek, gerektiğinde
                görevlerine son vermek,
              </li>
              <li>
                Vakıf tüzel kişiliği adına, bütün gerçek ve tüzel kişilerle
                hukuki, mali ve sair konularda gerekli girişimlerde bulunmak ve
                işlemler yapmak,
              </li>
              <li>
                Yürütme Komisyonu ve Danışma Komisyonunu kurmak, bunların
                program ve görevlerini saptamak,
              </li>
              <li>
                Vakfın muhasebe işlerini takip ve kontrol etmek, hesap dönemi
                sonunda Gelir-Gider Cetveli ve Bilançoların düzenlenerek ilgili
                idareye gönderilmesini ve ilanını sağlamak,
              </li>
              <li>
                Bankalarda vadesiz ya da vadeli Türk lirası ya da döviz hesabı
                açmak, vakfın amacı için vakıf parasını değerlendirmek,
              </li>
            </ol>
            <p>
              14-Her tür ve derecede vakfı temsil etmek ve bu bağlamda vekil
              tayin etmek,<br />
              15-Mütevelli Heyetine üye seçiminde teklifte bulunmak, İşbu Vakıf
              Senedinde öngörülmüş Yönetim Kuruluna ilişkin diğer hususları ifa
              etmek,<br />
              16-Vakfı temsil ve ilzam edecek imza yetkililerini saptamak,
              gayrimenkul satımlarına ilişkin özel yetkilerin verileceği kurul
              üyelerini saptamak,<br />
              17-Vakıf işlerinde ve idaresinde uygulanacak prensipleri tespit
              etmek, Kanun, Tüzük ve Resmi Senette belirtilmiş olan çeşitli
              konuları düzenlemek üzere yönetmelik ve talimatlar hazırlamak ve
              yürütmek,<br />
              18-Aynı yerleşim yeri içinde Vakfın yerleşim yeri değişikliği ile
              şube, temsilcilikler açılmasını karara bağlamak,<br />
              19-İlgili mevzuat ile vakıf senedi ve vakıf iç mevzuatının
              gerektirdiği diğer görevleri yapmak.
            </p>
            <ol>
              <ol>
                <li><strong>Yönetim Kurulu Toplantıları</strong></li>
                <ol>
                  <li>
                    Yönetim Kurulu Olağan ve Olağanüstü olmak üzere toplantıya
                    çağrılır. Bu olağan toplantılar dışında yönetim kurulu
                    başkanı, kurulu her zaman &ldquo;olağanüstü&rdquo;
                    toplantıya çağırabilir. Çağrı; Başkan yahut Başkan
                    yardımcısı tarafından yapılır. Gündem ve çağrı e-posta, sms
                    gibi güncel iletişim araçları aracılığıyla, toplantıdan en
                    az 30 gün önce gönderilir. Olağanüstü toplantıların ani
                    yapılması halinde, Başkan veya Başkan yardımcısının talebi
                    üzerine faks ve telefonla çağrı yapılır.
                  </li>
                  <li>
                    Yönetim Kurulu Olağan toplantıları en az üç ayda bir defa
                    yapılır. Olağanüstü toplantılar gerektiği her an yapılır.
                  </li>
                  <li>
                    Yönetim Kurulu, istişare niteliğinde, genişletilmiş
                    toplantılar yapılmasını kararlaştırabilir.
                  </li>
                  <li>
                    Toplantı yeter sayısı, yönetim kurulu üye tam sayısının
                    yarıdan bir fazlasıdır. Toplantı yeter sayı hesabında
                    küsurlar bütüne iblâğ olunur. Karar yeter sayısı ise,
                    toplantıda bulunan üye sayısının adî çoğunluğudur. Oylamada
                    oyların eşit olması halinde, Başkan&rsquo;ın oyu iki oy
                    sayılır.
                  </li>
                </ol>
              </ol>
            </ol>
            <h2><strong>Madde 10 – DENETİM KURULU</strong></h2>
            <ol>
              <ol>
                <li>Denetim kurulu 4 asıl ve 4 yedek üyeden oluşur.</li>
                <li>
                  Denetim kurulu üyelerinin hizmetleri fahrîdir, ücret talep
                  edilemez.
                </li>
                <li>Denetim kurulu üyeleri yeniden seçilebilirler.</li>
                <li>Denetim kurulu toplantısı en geç üç ayda bir yapılır.</li>
                <li>
                  Vakıf, Türk Medeni Kanunu çerçevesinde yapılacak denetleme
                  dışında, mütevelli heyetince seçilmiş üyeler tarafından her
                  yıl denetlenir.
                </li>
                <li>
                  Denetim kurulu üyeleri, vakfın yıllık hesaplarını, vakıf gelir
                  ve giderlerini, yapılan sarfiyatı denetleyerek görüşlerini
                  &ldquo;Denetim Kurulu Raporu&rdquo; adı altında mütevelli
                  heyetine sunarlar. Yönetim kurulu görev ve mesuliyetlerine
                  veya vakfın amaçlarına uygun olmayan işlemler varsa, denetim
                  kurulu raporlarında bunlar tavsiyeleriyle birlikte belirtilir.
                </li>
                <li>
                  Denetim kurulu üyeleri, yönetim kurulu toplantılarında müşahit
                  olarak hazır bulunabilirler. Ancak, oy hakları yoktur.
                </li>
                <li>
                  Vakıf, lüzum gördüğü takdirde, belli hususların incelenmesi
                  için, ücret karşılığı dışarıdan ilgili uzmanlara inceleme
                  yaptırabilir, mütalaa isteyebilir veya dış denetçi talep
                  edebilir.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 11 – VAKFIN TEMSİLİ:</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfı yönetim kurulu temsil eder. Yönetim kurulu bu temsil
                  yetkisini yönetim kurulu başkanı ve başkan yardımcısına
                  devredebilir.
                </li>
                <li>
                  Yönetim kurulu, genel veya belli hal ve konularda, belirteceği
                  esaslar dâhilinde kendi üyelerinden bir veya birkaçını,
                  yetkili memur ve memurlarından herhangi bir veya birkaçını,
                  temsilci veya temsilciliklerini, yurt içinde veya yurt dışında
                  herhangi bir sözleşmeyi akdetmeye, mukavele, hukuki belge veya
                  senedi vakıf nam ve hesabına tanzim ve devretmeye de yetkili
                  kılabilir.
                </li>
              </ol>
            </ol>
            <p>
              <strong>11.3</strong> Yönetim Kurulu, vakıf faaliyetlerinin
              yürütülmesi için mevzuata uygun olarak yurt içinde veya yurt
              dışında gerekli idari teşkilat ve kadroyu oluşturur. Her ilden
              seçilen temsilcilerin kadın ve hekim olma zorunluluğu vardır.
              İdari kadronun kimlerden oluşacağı, görev ve yetkileri, çalışma
              düzeni Yönetim Kurulu kararları ile saptanır.
            </p>
            <h1><strong>BÖLÜM III – ÜYELİK HÜKÜMLERİ</strong></h1>
            <h2><strong>Madde 12 –ÜYELİK ŞARTLARI</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakıf üyesi olmak için tıp fakültesi/diş hekimliği fakültesi
                  mezunu kadın hekim veya tıp /diş hekimliği fakültelerinde
                  öğretim üyesi kadın olma şartı bulunmaktadır.
                </li>
                <li>
                  Vakfa üyelikte; kısıtlı olmamak, yüz kızartıcı suçtan mahkum
                  edilmiş olmamak ile kadın hekim olmak ve vakfın amaçlarına
                  aykırılık teşkil etmemek ön koşuldur.
                </li>
                <li>
                  Vakfa; yabancı kadın hekimlerin de gerekli belgeleri ibraz
                  ederek üyelik kazanmaları mümkündür.
                </li>
                <li>Vakıf üyesi olmayan; bursiyer referansı olamayacaktır.</li>
              </ol>
            </ol>
            <h2><strong>Madde 13 – VAKIF ÜYELİĞİNİN KAZANILMASI</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakıf üyeliği &ldquo;üyelik talep formu&rdquo;nun doldurulup
                  vakfa gönderilmesi sureti ve Yönetim Kurulu kararı ile
                  kazanılır.
                </li>
                <li>Onursal üyelik de Yönetim Kurulu kararı ile kazanılır.</li>
              </ol>
            </ol>
            <h2><strong>Madde 14 - ÜYELİK AİDATI</strong></h2>
            <ol>
              <ol>
                <li>
                  Üyelerden vakfa girişlerde ve üyeliklerin devamında iki türlü
                  aidat alınır.
                </li>
              </ol>
            </ol>
            <p>
              a. Vakfa ilk defa üye olacaklardan; bir defa alınmak kaydıyla
              &ldquo;Üyelik Giriş Aidatı&rdquo;;<br />
              b. Vakıf üyelerinden; üyeliklerinin devamınca her yıl
              &ldquo;Yıllık Üye Aidatı&rdquo; alınır.
            </p>
            <ol>
              <ol>
                <li>
                  Aidatların nev&rsquo;i ve miktarı ile uygulama esasları her
                  yıl için Yönetim Kurulu kararı ile belirlenir.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 15 – ÜYELİĞİN SONA ERMESİ</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakıf üyeliği; Vakfın sona ermesi ile kendiliğinden sona
                  ereceği gibi; Ölüm, kadın hekimlerin hekimlik statüsünü
                  kaybetmesi, fiil ehliyetinin kaybedilmiş olması, yüz kızartıcı
                  suç işlenmesi, vakıf amaçlarına aykırı hareket edilmesi, vakıf
                  düzenini bozmaya yönelik hareketlerin tespiti, Yönetim Kurulu
                  tarafından belirlenen ihraç sebeplerinden birinin olması,
                  vakıf üyelik aidatının iki kez ödenmemesi, vakıf üyeliğinden
                  istifa edilmesi hallerinde de vakıf üyeliği sona erer.
                </li>
                <li>
                  Üyeliğin kazanılmasında ve kaybedilmesinde Yönetim Kurulu
                  Kararı esastır.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 16 – ONURSAL ÜYELİK</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfa ve vakfın amaçları doğrultusunda, yaptığı yararlı
                  faaliyetler ve üstün hizmet ve katkıları nedeniyle belirli
                  kişilere, Kadın Hekim Eğitime Destek Vakfı Onursal Üyeliği
                  verilebilir.
                </li>
                <li>
                  Kadın Hekimler Eğitime Destek Vakfı Onursal Üyeliği için,
                  hekim olma zorunluluğu aranmaz.
                </li>
                <li>
                  Vakıf Onursal Üyeliği için en az iki Yönetim Kurulu Üyesinin
                  takdimi şarttır. Vakıf Onursal Üyeliği vermek Yönetim Kurulu
                  yetkisindedir. Vakıf Onursal Üyeliğine ilişkin Yönetim Kurulu
                  kararının geçerliliği tam üye sayısının üçte ikisinin kabulü
                  koşuluna bağlıdır. Oylar kapalı verilir.
                </li>
                <li>Onursal üyelerden üyelik aidatı alınmaz.</li>
              </ol>
            </ol>
            <h2><strong>Madde 17 – ÜYELİKTEN AYRILMANIN SONUÇLARI</strong></h2>
            <ol>
              <ol>
                <li>
                  Her ne şekilde olursa olsun vakıf üyeliği sona erenlerin vakfa
                  olan borçları kesinleşir.
                </li>
                <li>Vakıftan ihraç edilenler, vakfa yeniden üye olamazlar.</li>
                <li>
                  Zorunlu sebeplerle görevlerinden ayrılan, vakıftan istifa eden
                  ve yıllık üye aidatlarını bu sebeple ödeyemeyenlerin gerekli
                  belgeleri ibrazı halinde üyelikleri dondurulur, görevlerine
                  yeniden döndüklerinde, vakıf aktif üyeliği dönemindeki
                  birikmiş üye aidat borçlarını toptan ödemek koşulu ile vakfa
                  üyelikleri devam eder.
                </li>
              </ol>
            </ol>
            <h1><strong>BÖLÜM IV – MALİ HÜKÜMLER</strong></h1>
            <h2><strong>Madde 18 – BAĞIŞLAR VE YARDIMLAR</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfa yapılacak bağışlar, yönetim kurulu kararı ile kabul
                  edilir. Bağışlar şartsız ve şartlı olarak yapılabilir. Şartsız
                  bağışlar vakfa gelir kaydolunarak, bağışlanan iktisadi
                  kıymetin mahiyetine ve mevcut ihtiyaca göre yönetim kurulu
                  kararı ile kullanılır veya sarf edilir.
                </li>
                <li>
                  Şartlı bağışlar, ancak bağışlayanın arzusu yönünde
                  kullanılabilir. Şu kadar ki, şartlı bağışların kabul
                  edilebilmesi için, mevcut şart veya mükellefiyetlerin vakfın
                  amacına aykırı düşmemesi gerekir.
                </li>
                <li>
                  Vakıf, mevzuat doğrultusunda yurtdışındaki kişi, kurum ve
                  kuruluşlardan bağış, aynî ve nakdî yardım alabilir.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 19 – VAKFIN GELİRLERİ</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfa ait menkul ve gayrimenkullerden elde edilen gelirler,
                </li>
                <li>
                  Vakfın kuracağı ya da ortak olacağı ticarî veya sınaî
                  şirketlerden, iştiraklerden sağlanan gelirler,
                </li>
                <li>
                  Vakfın amacına uygun olarak yapılacak bağışlar, vasiyet
                  yoluyla intikal eden gelirler,
                </li>
                <li>
                  Kültürel, sportif ve sosyal gibi vakfın tüm faaliyetlerinden
                  sağlanan gelirlerle, yayınlardan, vakfın amacına uygun olarak
                  yapılacak olan eğitim ve araştırma faaliyetlerinden elde
                  edilen gelirler,
                </li>
                <li>Üye aidatlarından elde edilen gelirler,</li>
                <li>
                  Paralı eğitim, konferans, sergi, organizasyon, vb. elde edilen
                  gelirler
                </li>
                <li>Sair gelirler</li>
              </ol>
            </ol>
            <h2>
              <strong>Madde 20 – VAKIF GELİRLERİNİN TAHSİS YERLERİ</strong>
            </h2>
            <ol>
              <ol>
                <li>
                  Vakıf yıl içinde elde ettiği brüt gelirin 1/3&rsquo;nü yönetim
                  ve idare masrafları ile ihtiyatlara ve vakıf malvarlığını
                  arttıracak yatırımlara; kalan 2/3&rsquo;ünü ise vakıf
                  amaçlarına sarf ve tahsise zorunludur.
                </li>
                <li>
                  Vakfın amaçlarına ayrılan ve bu doğrultuda harcanması gereken
                  miktarlar başka bir amaçla kullanılamaz.
                </li>
              </ol>
            </ol>
            <h2>
              <strong
                >Madde 21 – VAKFIN MALVARLIĞINI ARTIRICI BAĞIŞLAR VE BU
                BAĞIŞLARIN DEĞERLENDİRİLMESİ</strong
              >
            </h2>
            <ol>
              <ol>
                <li>
                  Vakfın malvarlığına ilâve edilmek üzere bağışlanan varlık ve
                  paralar vakfa en yüksek ve emin geliri sağlayacak yatırımlara
                  tahsis edilerek değerlendirilir. Vakıf bu amaçla
                  değerlendirilebilecek mevcutlarını vadeli veya vadesiz mevduat
                  şeklinde, mevzuata uygun olarak bankalara yatırır. Devlet
                  tahvili alabilir, güvenilir şirketlerin hisse senedi ve
                  tahvillerini satın alır ve gereğinde bunları satar veya sair
                  menkul kıymetler karşılığında değerlendirebilir. Vakfa
                  gayrimenkul mallar satın alabilir.
                </li>
                <li>
                  Gelirleri ve getirileri belli ve gerekli bir amaca tahsis
                  şartına bağlı olarak ve malvarlığını artırmak üzere yapılan
                  Türk Lirası şeklindeki bağışlar, Türk Medeni Kanunu&rsquo;nda
                  öngörülen amaca uygunluk şartı göz önünde bulundurularak
                  vakıfça mevzuata uygun olarak bankalara yatırılır. Bu
                  bağışlar, bağışta bulunanın isteği doğrultusunda yönlendirilir
                  ve değerlendirilir. Bağışların başlangıçtaki değerlerinin reel
                  olarak muhafazası ve bağış tutarının sürekliliğinin sağlanması
                  için vakıf gerekli tedbirleri alır.
                </li>
                <li>
                  Gelirleri ve getirileri, bağışlayanın vakıf amacına uygun
                  olarak koyacağı şartlar doğrultusunda kullanılmak üzere vakfa
                  gayrimenkul veya işletme tesisi bağışlanabilir. Bu şekilde
                  bağışlanan gayrimenkul ve tesislerin onarımı ve muhafazası
                  için gerekli giderler, bunların sağladıkları gelirlerden
                  karşılanır.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 22 – HESAP DÖNEMİ VE MUHASEBESİ</strong></h2>
            <ol>
              <ol>
                <li>Vakfın hesap dönemi takvim yılı itibariyledir.</li>
              </ol>
            </ol>
            <h1>
              <strong>BÖLÜM V - DİĞER HÜKÜMLER</strong>
            </h1>
            <h2><strong>Madde 23– VAKIF SENEDİNİN DEĞİŞTİRİLMESİ</strong></h2>
            <p>
              <strong>23.1.</strong> Vakıf resmi senedinde yapılacak
              değişiklikler ile ilgili karar, usulüne uygun yapılan mütevelli
              heyeti toplantısında mütevelli heyeti üye tam sayısının
              2/3&rsquo;ünün oyu ile alınan karar ve mahkeme kararı ile
              gerçekleştirilir.
            </p>
            <h2>
              <strong>Madde 24 – VAKFIN </strong
              ><strong>FESİH VE TASFİYESİ</strong>
            </h2>
            <p>
              <strong>24.1.</strong> Vakıf Senedinde belirtilen amaçların
              gerçekleşmesine fiilen ve hukuken imkân kalmaması halinde Yönetim
              Kurulunun teklifi üzerine Mütevelli Heyet üye tamsayısının
              2/3&rsquo;ünün oyu ile vakfın feshine karar verilir. Vakfın sona
              ermesi için dava açılarak karar alınır. Borçların tasfiyesinden
              artan mal ve haklar mütevelli heyetince belirlenecek bir vakfa,
              derneğe ya da kuruma devredilebilir.
            </p>
            <h2><strong>Madde 25– TASFİYE KURULU</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakfın tasfiyesinin gerekmesi halinde mahkemenin aksine bir
                  kararı olmadıkça, o tarihteki Yönetim Kurulu &ldquo;Tasfiye
                  Kurulu&rdquo; haline dönüşür ve bu sıfatla tasfiyenin
                  sonuçlanmasına kadar görevine devam eder.
                </li>
              </ol>
            </ol>
            <h2><strong>Madde 26 – TÖRENLER</strong></h2>
            <ol>
              <ol>
                <li>
                  Vakıf, meslek sahibi olan ve henüz okulu bitmemiş üniversite
                  öğrencisi olan bursiyerleriyle her 3 yılda bir tören tertip
                  eder.
                </li>
                <li>
                  Bu Tören, her 3 yılda bir Nisan ayında yapılır. Tören yeri ve
                  içeriği Yönetim Kurulu kararı ile belirlenir.
                </li>
              </ol>
            </ol>
            <h1>
              <strong>BÖLÜM VI - </strong
              ><strong>VAKIF ORGANLARINDAKİ GÖREVLİ İSİMLER</strong>
            </h1>
            <p>
              <strong>Madde 27 – İLK MÜTEVELLİ HEYETİ ÜYELERİ</strong><br />
              Demet BAŞER, TC Kimlik No:*****, Adres: *****, Mobil Telefon: ****
              <br />
              Tülin Pınar ESER, TC Kimlik No:*****, Adres: *****, Mobil Telefon:
              **** <br />
              Emine Çiğdem ÇATAL, TC Kimlik No:*****, Adres: *****, Mobil
              Telefon: **** <br />
              Canan TOPCU, TC Kimlik No:*****, Adres: *****, Mobil Telefon: ****
              <br />
              Burcu AKDAĞ ÖZKÖK, TC Kimlik No:*****, Adres: *****, Mobil
              Telefon: ****
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner.vue";

export default {
  components: {
    Banner
  },

  data() {
    return {
      meta: {
        title: "KAHEV Vakıf Senedi"
      }
    };
  },

  metaInfo() {
    const title = this.meta.title;

    return {
      title: title
    };
  }
};
</script>

<style lang="scss" scoped></style>
